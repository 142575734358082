import "../styles/fonts.css";
import React, { useState, useEffect, ReactElement, ReactNode } from "react";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { UserProvider } from "@auth0/nextjs-auth0";
import { SessionProvider, SessionProviderProps, useSession } from "next-auth/react"
import { SWRConfig } from "swr";
import fetcher from "lib/fetcher";
import axios from "axios";
import { useRouter } from "next/router";
import Script from "next/script";
import { Snackbar, Alert } from "@mui/material";
import Error from "pages/error";
import * as FullStory from "@fullstory/browser";
import { init } from "@amplitude/analytics-browser";
import { ColorModeProvider } from "contexts/ColorMode";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/700.css";
import fullstoryOrgId from "constants/fullstory-org-id";
import { hasCookie } from "cookies-next";
import { TrapDoorProvider } from "contexts/TrapDoor";
import CLIENT_URL from "constants/client-urls";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: {
    session: SessionProviderProps["session"]
  }
};

function Auth({ children } : { children: React.ReactElement}) {
  const router = useRouter();
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true, onUnauthenticated() {
     router.push("/api/vaulted-auth/signin")

  } })

  if (status === "loading") {
    return <div>Loading...</div>
  }
  return children
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [apiError, setApiError] = useState(null);
  // initialize FS here, but need to only run FS scripts like
  // FS.identify in layouts
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      FullStory.init({ orgId: fullstoryOrgId });
    }
    if (process.env.NEXT_PUBLIC_AMP_MC_API_KEY) {
      init(process.env.NEXT_PUBLIC_AMP_MC_API_KEY, undefined, {
        defaultTracking: {
          pageViews: true,
          sessions: true,
        },
      });
    }
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setApiError(null);
  };
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();
  // if the proxy layer passes along a 401
  // we log the user out
  axios.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      return router.replace(process.env.NEXT_PUBLIC_VAULTED_AUTH === "true" ? "/api/vaulted-auth/signout" : "/api/logout");
    }
    // preventing error pop up to not show on costs page when there is no access
    if (
      !(
        router.route === CLIENT_URL.settingsCosts &&
        error.response.status === 412
      )
    )
      setApiError(error.response.data.message);
    return Promise.reject(error);
  });

  const getProvider = (children: React.ReactElement) =>
    process.env.NEXT_PUBLIC_VAULTED_AUTH === "true" ? (
    <SessionProvider basePath="/api/vaulted-auth" session={pageProps?.session}>
      <Auth>
        {children}
      </Auth>
    </SessionProvider>
  ) : (
    <UserProvider
      profileUrl={hasCookie("tryAuthUser") ? "/api/try-auth/me" : undefined}
      loginUrl={hasCookie("tryAuthUser") ? "/api/try-auth/login" : undefined}
    >
      {children}
    </UserProvider>
  );

  const children = (
    getProvider(  
      <ColorModeProvider>
        <TrapDoorProvider>
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
            }}
          />
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
            <Component {...pageProps} />
          </SWRConfig>

          <Snackbar
            open={apiError !== null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={7000}
            onClose={handleClose}
          >
            <Alert variant="filled" severity="error">
              {apiError}
            </Alert>
          </Snackbar>
        </TrapDoorProvider>
      </ColorModeProvider>
    )
  );
  return getLayout(children);
}

// @ts-ignore
export default appWithTranslation(MyApp);
