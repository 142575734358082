import { PaletteMode } from "@mui/material";
import React, {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const LIGHT_THEME = "light";

export const DARK_THEME = "dark";

type ColorModeProviderType = {
  children: ReactNode;
};

function getInitialState() {
  let initialState: PaletteMode = LIGHT_THEME;
  // this is checking that we're on the browser
  if (typeof window !== "undefined") {
    initialState =
      (localStorage.getItem("MissionControlColorMode") as PaletteMode) ||
      (LIGHT_THEME as PaletteMode);
  }
  return initialState;
}

const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: getInitialState(),
});

function ColorModeProvider({ children }: ColorModeProviderType) {
  const [mode, setMode] = useState<PaletteMode>(getInitialState());
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      mode,
    }),
    [mode]
  );

  useEffect(() => {
    localStorage.setItem("MissionControlColorMode", mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      {children}
    </ColorModeContext.Provider>
  );
}

export { ColorModeProvider, ColorModeContext };
