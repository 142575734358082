import { ReactNode, useState, createContext, useMemo } from "react";

type ContextProps = {
  trapDoorOpen: boolean;
  setTrapDoorOpen: (arg1: boolean) => void;
  trapDoorAction: string | undefined;
  setTrapDoorAction: (arg2: string | undefined) => void;
};

export const TrapDoorContext = createContext<ContextProps>({
  trapDoorOpen: false,
  setTrapDoorOpen: () => {},
  trapDoorAction: undefined,
  setTrapDoorAction: () => {},
});

export function TrapDoorProvider({ children }: { children: ReactNode }) {
  const [trapDoorOpen, setTrapDoorOpen] = useState(false);
  const [trapDoorAction, setTrapDoorAction] = useState<string | undefined>();
  const value = useMemo(
    () => ({
      trapDoorOpen,
      setTrapDoorOpen,
      trapDoorAction,
      setTrapDoorAction,
    }),
    [trapDoorOpen, trapDoorAction]
  );
  return (
    <TrapDoorContext.Provider value={value}>
      {children}
    </TrapDoorContext.Provider>
  );
}
