const CLIENT_URL = {
  allWorkspaces: "/settings/workspace-admin",
  logout:
    process.env.NEXT_PUBLIC_VAULTED_AUTH === "true"
      ? "/api/vaulted-auth/signout"
      : "/api/logout",
  dashboard: "/dashboard",
  settings: "/settings",
  settingsRepositories: "/settings/repositories",
  settingsProfileInfo: "/settings/profile-info",
  settingsSshKeys: "/settings/ssh-public-keys",
  settingsCustomizations: "/settings/customizations",
  settingsDotfiles: "/settings/dotfiles",
  settingsCompanyInfo: "/settings/company-info",
  settingsUserManagement: "/settings/user-management",
  settingsGitProviders: "/settings/git-providers",
  settingsWireguardVpn: "/settings/wireguard-vpn",
  settingsFirewall: "/settings/firewall",
  settingsOrganizationManagement: "/settings/org-management",
  settingsSecrets: "/settings/secrets",
  settingsUserEnvironmentVariables: "/settings/user-environment-variables",
  settingsPolicyManagement: "/settings/policy-based-resource-management",
  settingsStaticIPs: "/settings/static-ips",
  settingsCosts: "/settings/costs",
  settingsAdminSetup: "/settings/setup",
};

export default CLIENT_URL;
